<template>
    <div class="contain">
        <searchBar :searchInput="false"></searchBar>
        <div class="bgf">
            <div class="fs_24 center color_333 padding_20 bottom_link">上传协议</div>
            <div class="main bgf">
                <div class="fs_20 color_333 bold">上传协议</div>
                <div class="fs_14 mt_10 color_333">为了规范平台灵活用工接单环节，企业发布灵活用工任务时必须提交本任务的相关协议，以供用户接单时签署。平台将会对双方盖章签署后的文件进行存证。</div>
                <div class="steps flex fs_14 color_333">
                    <div class="section flex_cen">
                        <p class="step-num fs_20 bold txt">1</p>
                        <img src="@/assets/imgs/step1.png" class="step-icon" alt="">
                        <p>将已盖章的灵活用工协议扫描或拍照依次上传，请确保文字清晰可见</p>
                    </div>
                    <div class="section flex_cen">
                        <p class="step-num fs_20 bold txt">2</p>
                        <img src="@/assets/imgs/step2.png" class="step-icon" alt="">
                        <p>选择其中需要用户签署的一页，作为签署页进行下一步</p>
                    </div>
                </div>
                <div class="upload">
                    <el-upload ref="upload" :action="`${baseApi}/api/v2/upload/image?filename=file&type=company`" accept=".jpg,.png" :on-change="onChange" :file-list="fileList" :headers="headers" :on-success="handleSuccess" list-type="picture-card">
                        <template #default>
                            <i class="el-icon-plus"></i>
                        </template>
                        <template #file="{file}">
                            <div>
                                <p class="fs_14 txt tip flex_cen" v-show="file.isSign">签署页</p>
                                <el-image class="el-upload-list__item-thumbnail" :src="file.url" fit="contain" alt="" />
                                <div class="el-upload-list__item-actions flex_cen">
                                    <p class="setting-btn flex_col btn" v-if="!file.isSign" @click="handleSetting(file)">
                                        <i class="el-icon-edit"></i>
                                        <span class="fs_14">设为签署页</span>
                                    </p>
                                    <p class="setting-btn flex_col btn" @click="handleRemove(file)">
                                        <i class="el-icon-delete"></i>
                                        <span class="fs_14">删除</span>
                                    </p>
                                </div>
                            </div>
                        </template>
                    </el-upload>
                    <div class="flex_cen mt_15">
                        <el-button type="info" class="next-btn mr_20" @click="back">暂不上传</el-button>
                        <el-button type="primary" class="next-btn" :disabled="fileList.length==0" @click="next">下一步</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { removeToken, getToken, s_setItem, s_getItem, s_clear, s_removeItem } from "@/common/cache"
export default {
	data() {
		return {
			id: '',
			baseApi: process.env.VUE_APP_API,
			headers: {
				Authorization: "Bearer " + getToken()
			},
			fileList: []
		}
	},
	created() {
		this.id = this.$route.params.id
		if (s_getItem('_agrs')) {
			this.fileList = s_getItem('_agrs')
		}
	},
	beforeRouteLeave(to, from, next) {
		s_removeItem('_drag_top')
		s_removeItem('_drag_left')

		if (to.name !== 'Setting') {
			s_clear()
		}
		next()
	},
	methods: {
		onChange(file, fileList) {
			console.log(file)
			let that = this
			let size = file.size / 1024 / 1024
			if (size > 5) {
				this.$message.warning('文件大小不能超过 5M')
				fileList.splice(fileList.length - 1, 1)
				return false
			}
			let img = new Image()
			img.src = file.url
			img.onload = function () {
				// console.log(img.width)
				// console.log(img.height)
				if (img.height < 1200 || size < 0.04) { // 高度 1200px   大小 40kb 以上
					that.$message.warning('请上传分辨率较高的文件')
					fileList.splice(fileList.length - 1, 1)
					return false
				}
			}
		},
		handleSuccess(res) {
			if (res.code !== 200) {
				if (res.code === 410000) {
					this.$message.error("请登录")
					removeToken()
					this.$router.push("/login")
				} else {
					this.$message.error(res.msg)
				}
			} else {
				//提交链接
				if (this.fileList.length != 0) {
					this.fileList.find(item => {
						item.isSign = false
					})
				}
				this.fileList.push({ url: res.data.url, isSign: true, id: this.fileList.length + 1 })
			}
		},
		// 设为签署页
		handleSetting(file) {
			this.fileList.forEach(item => {
				item.isSign = false
				if (item.id === file.id) {
					item.isSign = true
				}
			})
			this.$forceUpdate()
		},
		handleRemove(file) {
			this.fileList.forEach((item, index) => {
				if (item.id === file.id) {
					this.fileList.splice(index, 1)
				}
			})
			if (file.isSign) {
				this.fileList[this.fileList.length - 1].isSign = true
			}
		},
		back() {
			this.$router.replace('/employment')
		},
		next() {
			s_setItem('_agrs', this.fileList)
			this.$router.push(`/employment/setting/${this.id}`)
		}
	}
}
</script>

<style lang='less' scoped>
.next-btn {
    width: 200px;
    height: 52px;
    font-size: 18px;
    margin-top: 80px;
}
.mr_20 {
    margin-right: 20px;
}
.main {
    padding: 50px 100px;
    .steps {
        margin-top: 20px;
        .section {
            width: 480px;
            height: 120px;
            border: 1px dashed #d7deec;
            border-radius: 2px;
            padding: 0 80px 0 90px;
            position: relative;
            line-height: 1.6;
            &::after {
                position: absolute;
                width: 0;
                height: 0;
                border-top: 60px solid transparent;
                border-left: 44px solid #d7deec;
                border-bottom: 60px solid transparent;
                content: "";
                top: 0;
                left: 0;
                z-index: 3;
            }
            .step-num {
                position: absolute;
                top: 50%;
                left: 12px;
                z-index: 9;
                transform: translateY(-50%);
            }
        }
        .step-icon {
            width: 70px;
            height: 70px;
            margin-right: 8px;
        }
    }
    .upload {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px dashed #d7deec;
        .setting-btn {
            width: 98px;
            height: 84px;
            // border: 1px dashed #d7deec;
            border-radius: 6px;
            justify-content: center;
            color: #d7deec;
            &:hover {
                color: #fff;
            }
        }
        .tip {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 9;
            background: #1890ff;
            width: 82px;
            height: 32px;
            border-radius: 0 0 0 16px;
        }
    }
}
/deep/.el-upload--picture-card {
    width: 200px;
    height: 200px;
    line-height: 200px;
}
/deep/.el-upload-list--picture-card .el-upload-list__item {
    width: 200px;
    height: 200px;
}
</style>